import React, { Fragment } from "react";
import Seo from "../seo";
import { Link } from "gatsby";
// import Layout from "../layout"
// import {navigate} from 'gatsby';
import HasuraConHeader from "../hasuracon/hasuraconheader";
import "../hasuracon/styles.scss";
import Footer from "../common/footer";
import HasuraConFooter from "../hasuracon/hasuraconfooter";
import { talksDetails, scheduleDetails } from "./AllState.js";
import back from "../hasuracon/images/back.svg";
import hasConLogo from "../hasuracon/images/has-con-logo.svg";
// const calendar = require('../hasuracon/images/calendar.svg');
// const calendarSuccess = require('../hasuracon/images/calendar-success.svg');
// const frameBg = require('./images/frame-bg.svg');
// const type = require('./images/type.svg');
// const level = require('./images/level.svg');
import twitterWhite from "./images/twitter-white.svg";
import discordWhite from "./images/discord-white.svg";
import githubWhite from "./images/github-white.svg";
import linkedinWhite from "./images/linkedin-white.svg";
import org from "./images/org.svg";
import leftArrow from "./images/left-arrow.svg";
import rightArrow from "./images/right-arrow.svg";

const TalksDetails = props => {
  const talksUrl = props.pageContext.slug;
  // const currenttalksFilter = talksDetails.filter((b) => b.url === talksUrl)
  // const currentTalks = currenttalksFilter[0]
  const currentScheduleFilter = scheduleDetails.filter(b => b.url === talksUrl);
  const currentSchedule = currentScheduleFilter[0];
  const currenttalksFilter = talksDetails.filter(b => b.url === currentSchedule.url);
  const currentTalks = currenttalksFilter[0];
  const currentIndex = scheduleDetails.indexOf(currentScheduleFilter[0]);
  const previous =
    currentIndex === 0 ? scheduleDetails[currentIndex] : scheduleDetails[currentIndex - 1];
  const currentPreviousFilter = talksDetails.filter(b => b.url === previous.url);
  const currentPrevious = currentPreviousFilter[0];
  const next =
    currentIndex === scheduleDetails.length - 1
      ? scheduleDetails[scheduleDetails.length - 1]
      : scheduleDetails[currentIndex + 1];
  const currentNextFilter = talksDetails.filter(b => b.url === next.url);
  const currentNext = currentNextFilter[0];
  if (!currenttalksFilter) {
    if (typeof window !== undefined) {
      window.location.href = "/404";
    }
  }
  const ogImage = { ogImage: currentTalks.metaTags.metaImg };
  return (
    <div>
      <Seo
        title={currentTalks.title + " | HasuraCon 2020"}
        description={
          currentTalks.description ? currentTalks.description.lists[0] : currentTalks.title
        }
        meta={ogImage}
        canonicalLink={currentTalks.metaTags.canonicalUrl}
      />
      <HasuraConHeader location={props.location} />
      <section className="blueGradientBgColor topBannerWrapperHasuraConRel">
        <div className="frameBg patternImg">
          <img src={currentTalks.frameBg} alt="Frame bg" />
        </div>
        <div className="addTopBannerPaddRegister">
          <div className="containerWrapper">
            <div className="talksDetailsWrapper wd100">
              <div className="backHasuraConf">
                <Link to="/events/hasura-con-2020/">
                  <img src={back} alt="back" />
                  <span>Conference page</span>
                </Link>
              </div>
              <div className="frameCon">
                <img src={hasConLogo} alt="Hasuracon20" />
              </div>
              <div className="col-md-8 col-sm-7 col-xs-12">
                <div className="talkTilte">Talk title</div>
                <h1 className="title textLeft">{currentTalks.title}</h1>
                <div className="timeCalendarWrapper">
                  <div className="timeWrapper">
                    {currentTalks.date && currentTalks.time ? (
                      <div className="bodyTextSmallCon">
                        <div className="fontBold">
                          <span>{currentTalks.date}</span> · <span>{currentTalks.time}</span>
                        </div>
                      </div>
                    ) : null}
                    {currentTalks.duration ? (
                      <div className="bodyTextSmallCon">{currentTalks.duration}</div>
                    ) : null}
                  </div>
                  {/*
                  <div className='addCalenderWrapper'>
                    <div
                    onKeyDown= {() => { addToCalenderFunc()}}
                    onClick = {() => { addToCalenderFunc()}}
                    tabIndex="0" role="button"
                    className='addCalender'
                    >
                      <div
                      className={'circleCalendar' + ((addToCalendar) ? ' circleActive' : '')}
                      >
                      <img src={(addToCalendar) ? calendarSuccess : calendar} alt='calendar'/>
                      </div>
                      <div className='extraSmallBodyTextCon'>
                      {
                      (addToCalendar) ? 'Added' : 'Add to calendar'
                      }
                      </div>
                    </div>
                  </div>
                  */}
                </div>
                {currentTalks.talkType ? (
                  <div className="talkTypeWrapper">
                    {currentTalks.talkType.map((list, index) => {
                      return (
                        <div key={index} className="talkType extraSmallBodyTextCon">
                          <img src={list.Icon} alt="Type" />
                          {list.title}
                        </div>
                      );
                    })}
                  </div>
                ) : null}
                <div className="line"></div>
                {currentTalks.abstract ? (
                  <div className="talkDetailsWrapper">
                    <div className="smallParaHeaderCon">Abstract</div>
                    <div className="bodyTextSmallCon">{currentTalks.abstract}</div>
                  </div>
                ) : null}
                {currentTalks.description ? (
                  <div className="talkDetailsWrapper">
                    <div className="smallParaHeaderCon">Description</div>
                    {currentTalks.description.lists.map((list, index) => {
                      return (
                        <div key={index} className="bodyTextSmallCon addPaddDescription">
                          {list}
                        </div>
                      );
                    })}
                  </div>
                ) : null}
                {currentTalks.takeaways ? (
                  <div className="talkDetailsWrapper">
                    <div className="smallParaHeaderCon">Takeaways</div>
                    {currentTalks.takeaways.description ? (
                      <div className="bodyTextSmallCon">{currentTalks.takeaways.description}</div>
                    ) : null}
                    {currentTalks.takeaways.lists ? (
                      <div className="bodyTextSmallCon">
                        <ul>
                          {currentTalks.takeaways.lists.map((list, index) => {
                            return <li key={index}>{list}</li>;
                          })}
                        </ul>
                      </div>
                    ) : null}
                  </div>
                ) : null}
                {!currentTalks.abstract && !currentTalks.description && !currentTalks.takeaways ? (
                  <div className="bodyTextSmallCon">Coming Soon</div>
                ) : null}
                <div className="buttonWrapper">
                  <a href={currentTalks.videoLink} target="_blank" rel="noopener noreferrer">
                    <button className="commonBtnCon hasuraConRegBtn">Watch The Video</button>
                  </a>
                </div>
              </div>
              <div className="col-md-4 col-sm-5 col-xs-12">
                {currentTalks.speakersList.map((speaker, index) => {
                  return (
                    <div key={index} className="spekersDetailsWrapper">
                      <div className="speakerImg">
                        <img src={speaker.img} alt={speaker.name} />
                      </div>
                      <div className="speakersDescriptionWrapper">
                        <div className="speakerName sectionHeaderCon">{speaker.name}</div>
                        <div className="speakerdesignation bodyTextSmallCon">
                          {speaker.designation}
                        </div>
                        {speaker.org ? (
                          <div className="speakerOrg bodyTextSmallCon">
                            <a href={speaker.orgLink} target="_blank" rel="noopener noreferrer">
                              {speaker.org}
                            </a>
                          </div>
                        ) : null}
                        <div className="speakerSocialWrapper">
                          {speaker.twitterLink ? (
                            <a href={speaker.twitterLink} target="_blank" rel="noopener noreferrer">
                              <div className="socialShare">
                                <img src={twitterWhite} alt="Twitter" />
                              </div>
                            </a>
                          ) : null}
                          {speaker.discordLink ? (
                            <a href={speaker.discordLink} target="_blank" rel="noopener noreferrer">
                              <div className="socialShare">
                                <img src={discordWhite} alt="Discord" />
                              </div>
                            </a>
                          ) : null}
                          {speaker.personalLink ? (
                            <a
                              href={speaker.personalLink}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <div className="socialShare">
                                <img src={org} alt="Icon" />
                              </div>
                            </a>
                          ) : null}
                          {speaker.githubLink ? (
                            <a href={speaker.githubLink} target="_blank" rel="noopener noreferrer">
                              <div className="socialShare">
                                <img src={githubWhite} alt="Github" />
                              </div>
                            </a>
                          ) : null}
                          {speaker.linkedInLink ? (
                            <a
                              href={speaker.linkedInLink}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <div className="socialShare">
                                <img src={linkedinWhite} alt="Linkedin" />
                              </div>
                            </a>
                          ) : null}
                        </div>
                        {speaker.about ? (
                          <Fragment>
                            <div className="speakerAbout">About {speaker.name}</div>
                            <div className="speakerDescription extraSmallBodyTextCon">
                              {speaker.about}
                            </div>
                          </Fragment>
                        ) : null}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="paginationWrapper">
              <Link
                to={"/events/hasura-con-2020/talks/" + previous.url + "/"}
                className={"previousWrapper hidden-xs" + (currentIndex === 0 ? " isDisabled" : "")}
              >
                <div className="previousArrow">
                  <img src={leftArrow} alt="Previous" />
                </div>
                <div className="paginationImg">
                  <img src={currentPrevious.paginationImg} alt={currentPrevious.paginationName} />
                </div>
                <div className="paginationTalkDetails">
                  <div className="paginationTalk">Previous talk</div>
                  <div className="paginationTalkTitle bodyTextSmallCon">
                    {currentPrevious.title}
                  </div>
                </div>
              </Link>
              <Link
                to={"/events/hasura-con-2020/talks/" + next.url + "/"}
                className={
                  "nextWrapper" + (currentIndex === scheduleDetails.length - 1 ? " isDisabled" : "")
                }
              >
                <div className="paginationTalkDetails">
                  <div className="paginationTalk">Next talk</div>
                  <div className="paginationTalkTitle bodyTextSmallCon">{currentNext.title}</div>
                </div>
                <div className="paginationImg">
                  <img src={currentNext.paginationImg} alt={currentNext.paginationName} />
                </div>
                <div className="nextArrow">
                  <img src={rightArrow} alt="Next" />
                </div>
              </Link>
              <Link
                to={"/events/hasura-con-2020/talks/" + previous.url + "/"}
                className={
                  "previousWrapper mobileShowPrevious" + (currentIndex === 0 ? " isDisabled" : "")
                }
              >
                <div className="previousArrow">
                  <img src={leftArrow} alt="Previous" />
                </div>
                <div className="paginationImg">
                  <img src={currentPrevious.paginationImg} alt={currentPrevious.paginationName} />
                </div>
                <div className="paginationTalkDetails">
                  <div className="paginationTalk">Previous talk</div>
                  <div className="paginationTalkTitle bodyTextSmallCon">
                    {currentPrevious.title}
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <HasuraConFooter location={props.location} />
      </section>
      <Footer location={props.location} />
    </div>
  );
};

export default TalksDetails;
